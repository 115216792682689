import { useSearchParams } from 'next/navigation'

import { LocalStorage } from 'src/@types/LocalStorage'
import securityHelpers from 'src/config/security'

const parseGracefully = (key: string): string => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) {
    return ''
  }
}

export const useBiometricLoginState = () => {
  const biometricLoginActive = parseGracefully(LocalStorage.BiometricLoginActive)
  const biometricLoginDismissed = parseGracefully(LocalStorage.BiometricLoginDismissed)
  const search = useSearchParams()

  const biometricLoginReset = () => {
    localStorage.removeItem(LocalStorage.BiometricLoginDismissed)
    localStorage.removeItem(LocalStorage.BiometricLoginActive)
  }

  const checkDeviceCompatibility = async () => {
    let result = true

    if (!('credentials' in navigator)) {
      result = false
    }

    if (!window.PublicKeyCredential) {
      result = false
    }

    try {
      if (navigator.credentials.preventSilentAccess) {
        await navigator.credentials.preventSilentAccess()
      } else {
        result = false
      }
    } catch (e) {
      result = false
    }

    return result
  }

  const shouldOfferBiometricLogin = async () => {
    return (
      !biometricLoginActive &&
      !biometricLoginDismissed &&
      securityHelpers.getConfig({ search }).successPath === '/' &&
      (await checkDeviceCompatibility()) &&
      (localStorage.getItem(LocalStorage.TrustedDeviceId)
        ? !!JSON.parse(localStorage.getItem(LocalStorage.TrustedDeviceId))
        : '')
    )
  }

  return {
    biometricLoginActive,
    biometricLoginReset,
    checkDeviceCompatibility,
    biometricLoginDismissed,
    shouldOfferBiometricLogin,
  }
}
