import React from 'react'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { Box } from '@mui/material'

import { LinkButton } from 'src/components/LinkButton/LinkButton.styles'

const FormAppendage = () => {
  const { t } = useTranslation()

  return (
    <Box textAlign="right">
      <Link href={'/forgot-password'} passHref>
        <LinkButton>{t('b2c.login_form.forgotPasswordLink')}</LinkButton>
      </Link>
    </Box>
  )
}

export default FormAppendage
