import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FirstGridItem = styled(Grid)({
  paddingTop: '0px!important',
})

FirstGridItem.defaultProps = {
  item: true,
  xs: 12,
}
