import React, { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { AuthError, LogoutKind } from '@dentalux/security'

import { LoginFormFields } from 'src/@types/Login'
import AuthLayout from 'src/components/AuthLayout'
import { useLogoutState } from 'src/components/providers/LogoutKindProvider'
import usePrepareErrorMessage from 'src/hooks/usePrepareErrorMessage'

import FormAppendage from './components/FormAppendage'
import LoginForm from './components/LoginForm'
import LoginSubheading from './components/LoginSubheading'

type Props = {
  onSubmit: (data: LoginFormFields) => void
  processing?: boolean
  error?: AuthError | null
}

const LoginScreen = ({ onSubmit, processing, error }: Props) => {
  const { t } = useTranslation()
  const { state } = useLogoutState()

  const { isReady, prefetch, query } = useRouter()

  useEffect(() => {
    for (const item of ['/', '/mfa', '/forgot-password', '/registration']) {
      prefetch(item)
    }
  }, [])

  return (
    <AuthLayout
      heading={t('b2c.login.heading')}
      subHeading={<LoginSubheading />}
      error={usePrepareErrorMessage(error)}
      success={state === LogoutKind.PHONE_NUMBER_CHANGED && t('b2c.login.phone_number_changed')}
      warning={(() => {
        if (query.error === 'session_expired') return t('b2c.login.auto_logout')

        if (state === LogoutKind.AUTO) return t('b2c.login.auto_logout')

        if (state === LogoutKind.PHONE_NUMBER_CHANGE_TOKEN_EXPIRED) return t('b2c.login.phone_number_token_expired')
      })()}
    >
      {isReady && <LoginForm onSubmit={onSubmit} isLoading={processing} appendToForm={<FormAppendage />} />}
    </AuthLayout>
  )
}

export default LoginScreen
