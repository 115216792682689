import { useTranslation } from 'next-i18next'

import * as yup from 'yup'

const useLoginFormValidation = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    email: yup.string().email(t('b2c.validation.invalid')).required(t('b2c.validation.required')),
    password: yup.string().required(t('b2c.validation.required')),
  })
}

export default useLoginFormValidation
