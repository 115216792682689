import React, { ReactNode, ReactElement, useRef } from 'react'

import { useTranslation } from 'next-i18next'
import Head from 'next/head'

import { useSmoothScroll } from 'src/hooks/useSmoothScroll'
import ExternalTemplate from 'src/templates/ExternalTemplate'

import * as S from './AuthLayout.styles'

type Props = {
  heading?: string
  subHeading?: string | ReactNode
  extra?: ReactNode
  error?: string | string[] | ReactElement
  success?: string | null
  warning?: string | null
  children?: ReactNode | ReactNode[]
}

const AuthLayout = ({ heading, subHeading, children, error, success, warning, extra }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  useSmoothScroll(error ? ref : null, ref.current?.offsetTop)

  return (
    <>
      <Head>
        <title>{t('b2c.login.page.title')}</title>
        <meta name="description" content={t('b2c.login.page.descrition')} />
      </Head>
      <ExternalTemplate>
        <S.Container>
          <S.Content>
            <S.Heading>{heading}</S.Heading>
            <S.SubHeading>{subHeading}</S.SubHeading>

            <S.FormContainer ref={ref}>
              {extra}

              {error && <S.Alert severity="error">{error}</S.Alert>}
              {warning && <S.Alert severity="warning">{warning}</S.Alert>}
              {success && <S.Alert severity="success">{success}</S.Alert>}

              {children}
            </S.FormContainer>
          </S.Content>
        </S.Container>
      </ExternalTemplate>
    </>
  )
}

export default AuthLayout
