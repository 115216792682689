import React, { ReactNode } from 'react'

import * as S from './LinkText.styles'

const LinkText = ({
  href,
  children,
  target,
  rel,
  className,
}: {
  href: string
  target?: string
  children?: ReactNode | ReactNode[]
  rel?: string
  className?: string
}) => (
  <S.Root className={className} target={target} rel={rel} href={href || ''} passHref>
    {children}
  </S.Root>
)

export default LinkText
