import Link from 'next/link'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { styled } from '@mui/material/styles'

export const Root = styled(Link)({
  color: colors.aqua[800],

  ':visited': {
    color: colors.aqua[800],
  },
})
