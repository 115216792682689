import base64 from 'base64-js'

const enc = new TextEncoder()

const padBase64String = (val: string): string => {
  let rem = val.length % 4
  while (rem < 4) {
    val += '='
    rem++
  }
  return val
}

const base64urlEncode = (val: ArrayBuffer) =>
  window.btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''))

export const getAttestation = async (
  id: string,
  email: string,
  name: string,
  challenge: string
): Promise<PublicKeyCredential | null> => {
  let result

  try {
    result = await navigator.credentials.create({
      publicKey: {
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        challenge: enc.encode(challenge),
        rp: { id: document.domain, name: 'Patient21 GmbH' },
        user: {
          id: enc.encode(id),
          name: email,
          displayName: name,
        },
        pubKeyCredParams: [
          { type: 'public-key', alg: -7 },
          { type: 'public-key', alg: -257 },
        ],
      },
    })
  } catch (e) {
    console.log(e)
  }

  return (result as PublicKeyCredential) || null
}

export const verifyAttestation = async (challenge: string, publicKey: string) => {
  const arr = base64.toByteArray(padBase64String(publicKey))

  let result

  try {
    result = await navigator.credentials.get({
      publicKey: {
        challenge: enc.encode(challenge),
        rpId: document.domain,
        allowCredentials: [
          {
            type: 'public-key',
            id: arr,
          },
        ],
        userVerification: 'required',
      },
    })
  } catch (e) {
    console.log(e)
  }

  return result
}

export const publicKeyCredentialToJSON = (pubKeyCred) => {
  if (pubKeyCred instanceof ArrayBuffer) {
    return base64urlEncode(pubKeyCred)
  }

  if (Array.isArray(pubKeyCred)) {
    return pubKeyCred.map(publicKeyCredentialToJSON)
  }

  if (pubKeyCred && typeof pubKeyCred === 'object') {
    const obj = {}

    for (const key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key])
    }

    return obj
  }

  return pubKeyCred
}
