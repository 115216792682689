import React, { ChangeEvent, useState } from 'react'

import { useForm, Controller, SubmitHandler, ControllerRenderProps } from 'react-hook-form'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Grid } from '@mui/material'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoginFormFields } from 'src/@types/Login'
import { Continue } from 'src/components/AuthLayout/components/Continue/Continue'
import FirstGridItem from 'src/components/FirstGridItem'
import { EmailInputFilled, PasswordInputFilled } from 'src/components/inputs'
import { DataTestIds } from 'src/config/dataTestIds'

import * as S from './LoginForm.styles'
import useLoginValidation from './LoginForm.validation'

type Props = {
  onSubmit: SubmitHandler<LoginFormFields>
  isLoading?: boolean
  appendToForm?: React.ReactElement // Used to append elements after the submit button
}

const LoginForm = ({ onSubmit, isLoading, appendToForm }: Props) => {
  const { t } = useTranslation()

  const validation = useLoginValidation()

  const [autoFilled, setAutoFilled] = useState({
    email: false,
    password: false,
  })

  const { query } = useRouter()

  const email = (query?.email as string) || ''

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm<LoginFormFields>({
    defaultValues: email ? { email } : undefined,
    resolver: yupResolver(validation),
    mode: 'onChange',
  })

  const handleAutoFill = (
    field: ControllerRenderProps<LoginFormFields, 'email'> | ControllerRenderProps<LoginFormFields, 'password'>
  ) => {
    setAutoFilled((prev) => ({ ...prev, [field.name]: true }))
  }

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: ControllerRenderProps<LoginFormFields, 'email'> | ControllerRenderProps<LoginFormFields, 'password'>
  ) => {
    setAutoFilled({ ...autoFilled, [field.name]: false })
    field.onChange(e)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <FirstGridItem>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <EmailInputFilled
                autoFocus
                name={field.name}
                onAutoFill={() => handleAutoFill(field)}
                onChange={(e) => handleChange(e, field)}
                onBlur={field.onBlur}
                value={field.value}
                autoComplete={email ? 'off' : undefined}
                dataTestId={DataTestIds.RegistrationEmail}
                inputError={touchedFields.email && errors.email}
                label={t('b2c.login_form.emailLabel')}
                required
              />
            )}
          />
        </FirstGridItem>
        <S.GridItem>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <PasswordInputFilled
                name={field.name}
                onAutoFill={() => handleAutoFill(field)}
                onChange={(e) => handleChange(e, field)}
                onBlur={field.onBlur}
                value={field.value}
                dataTestId={DataTestIds.RegistrationPassword}
                inputError={touchedFields.password && errors.password}
                label={t('b2c.login_form.passwordLabel')}
                required
              />
            )}
          />
        </S.GridItem>
        {appendToForm && <S.LastGridItem>{appendToForm}</S.LastGridItem>}
      </Grid>

      <Continue
        data-testid={DataTestIds.SubmitButton}
        onClick={() => setAutoFilled({ email: false, password: false })}
        disabled={Object.values(autoFilled).some((el) => !el) && (!isValid || isLoading)}
      >
        {t('b2c.login_form.submit')}
      </Continue>
    </S.Form>
  )
}

export default LoginForm
