import { useEffect } from 'react'

export const useSmoothScroll = (scrollTargetRef: React.MutableRefObject<HTMLElement | null>, offset = 0) => {
  useEffect(() => {
    if (!scrollTargetRef?.current) return

    const top = scrollTargetRef.current.offsetTop - offset
    window.scrollTo({ top, left: 0, behavior: 'smooth' })
  }, [scrollTargetRef, offset])
}
