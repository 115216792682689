import { useState } from 'react'

import { useQuery } from 'react-query'

import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { useSetCredentials } from '@dentalux/security'

import api from 'api'
import {
  publicKeyCredentialToJSON,
  verifyAttestation,
} from 'src/components/dialogs/BiometricLoginDialog/biometrics.utils'
import securityHelpers from 'src/config/security'

import { useBiometricAuthenticate } from './api/useBiometricAuthenticate'

export const useBiometricalLogin = ({
  onSuccess,
  enabled,
  trustedDeviceReferenceId,
}: {
  onSuccess: (token: string) => void
  enabled: boolean
  trustedDeviceReferenceId?: string
}) => {
  const { mutate: biometricAuthenticate } = useBiometricAuthenticate()
  const [refetchOnWindowFocus, setRefetchOnWindowFocus] = useState<boolean>(true)

  const router = useRouter()

  const search = useSearchParams()

  const setCredentials = useSetCredentials()

  useQuery(
    ['biometrical-preauthenticate', trustedDeviceReferenceId],
    () => api.hades.getBiometricPreAuthenticate({ trustedDeviceReferenceId }),
    {
      refetchOnWindowFocus,
      refetchOnReconnect: false,
      onSuccess: (preAuth) => {
        if (window.document.hasFocus()) {
          setRefetchOnWindowFocus(false)

          verifyAttestation(preAuth.challenge, preAuth.publicKey).then((attestation) => {
            const credential = publicKeyCredentialToJSON(attestation)

            biometricAuthenticate(
              { trustedDeviceReferenceId, credential },
              {
                onSuccess: ({ user, token, refreshToken }) => {
                  setCredentials({ authentication: user, token, refreshToken }, 'local')

                  onSuccess(token)

                  router.push(securityHelpers.getConfig({ search }).successPath)
                  window.document.body.focus()
                },
              }
            )
          })
        }
      },
      enabled: !!trustedDeviceReferenceId && enabled,
      retry: false,
    }
  )
}
