import React, { ReactElement } from 'react'

import { Trans, useTranslation } from 'next-i18next'

import { AuthError, FieldError, MFAError } from '@dentalux/security'

import LinkText from 'src/components/LinkText/LinkText'
import { ErrorCodes } from 'src/config/errorCodes'

const usePrepareErrorMessage = (
  error: AuthError | MFAError | string | string[] | null
): string | string[] | ReactElement => {
  const { t } = useTranslation()
  if (!error) return ''

  if (typeof error === 'string') return error

  if (Array.isArray(error)) {
    return error.map((error) => error)
  }

  if ('fields' in error && error?.fields && error?.fields?.length > 0) {
    return error?.fields?.map((field: FieldError) => field.message)
  }

  if (error?.code || error.status === 404) {
    if (error?.code === ErrorCodes.RegistrationTokenExpired || error?.code === ErrorCodes.PatientTokenDisabled) {
      return <Trans i18nKey={error.code} components={[<LinkText key="registration" href="/registration" />]} />
    }

    return t(error?.code) || t(ErrorCodes.Fallback)
  }

  return error?.message || ''
}

export default usePrepareErrorMessage
