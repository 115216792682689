import { Typography, Alert as BaseAlert } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}))

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  boxShadow: 'initial',
}))

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: 54,
  justifyContent: 'space-betwean',
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    minHeight: 54,
  },
}))

export const ToolbarContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: 1,
}))

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  maxWidth: 460,
  margin: '0 auto',
}))

export const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingTop: theme.spacing(2),
}))

export const Heading = styled(Typography)(() => ({}))

Heading.defaultProps = { variant: 'DM Serif H3' }

export const SubHeading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),

  '& a': {
    ...theme.typography['Lato Link 1'],
    color: theme.palette.primary.main,
  },
}))

SubHeading.defaultProps = { variant: 'Lato Body 1' }

export const Alert = styled(BaseAlert)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  a: {
    color: 'inherit',
  },
}))
