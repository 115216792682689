import React, { useEffect } from 'react'

import { Trans } from 'next-i18next'
import { useSearchParams } from 'next/navigation'

import LinkText from 'src/components/LinkText/LinkText'
import {
  setBookingLink,
  useUnauthorizedContext,
  useUnauthorizedDispatch,
} from 'src/components/providers/UnauthorizedProvider'

const LoginSubheading = () => {
  const dispatch = useUnauthorizedDispatch()
  const { bookingLink } = useUnauthorizedContext()

  const search = useSearchParams()
  const searchBookingLink = search.get('bookingLink')

  useEffect(() => {
    if (searchBookingLink) {
      dispatch(setBookingLink(searchBookingLink))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBookingLink])

  return bookingLink ? (
    <Trans i18nKey="b2c.login.external.subheading" components={[<LinkText key="booking-link" href={bookingLink} />]} />
  ) : (
    <Trans i18nKey="b2c.login.subheading" components={[<LinkText key="registration-link" href="/registration" />]} />
  )
}

export default LoginSubheading
