import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Button)(({ theme }) => ({
  margin: theme.spacing(4, 0, 1, 0),
}))

Root.defaultProps = {
  fullWidth: true,
  type: 'submit',
  color: 'aqua',
  size: 'lg',
}
