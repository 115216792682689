import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: theme.spacing(2.5),
  flex: 1,
}))

export const GridItem = styled(Grid)({})

GridItem.defaultProps = {
  item: true,
  xs: 12,
}

export const LastGridItem = styled(GridItem)(({ theme }) => ({
  paddingTop: theme.spacing(1) + '!important',
}))
