import { useMutation } from 'react-query'

import api from 'api'

export const useBiometricAuthenticate = () => {
  return useMutation(
    ({ trustedDeviceReferenceId, credential }: { trustedDeviceReferenceId: string; credential }) =>
      api.hades.postBiometricAuthenticate({ trustedDeviceReferenceId, credential }),
    { useErrorBoundary: false }
  )
}
