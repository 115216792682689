import React from 'react'

import { useLogin, decodeToken } from '@dentalux/security'

import { LoginFormFields } from 'src/@types/Login'
import { DialogNames } from 'src/components/dialogs/DialogRoot'
import { useDialog } from 'src/components/providers/DialogProvider'
import { useLogoutState } from 'src/components/providers/LogoutKindProvider'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import { useSnackbar } from 'src/components/providers/SnackbarProvider'
import {
  useUnauthorizedDispatch,
  setEmail,
  reset,
  useUnauthorizedContext,
} from 'src/components/providers/UnauthorizedProvider'
import { useBiometricalLogin } from 'src/hooks/useBiometricalLogin'
import { useBiometricLoginState } from 'src/hooks/useBiometricLoginState'
import { useRedirectUrlHandling } from 'src/hooks/useRedirectUrlHandling'
import { useTrustedDeviceId } from 'src/hooks/useTrustedDeviceId'
import LoginScreen from 'src/screens/Login'
import withTranslation from 'src/staticProps/withTranslation'

const LoginPage = () => {
  const unauthorizedDispatch = useUnauthorizedDispatch()

  const trustedDeviceReferenceId = useTrustedDeviceId()
  const { biometricLoginActive, shouldOfferBiometricLogin } = useBiometricLoginState()
  const { biometricLoginDisabled } = useUnauthorizedContext()

  const { openSnackbar } = useSnackbar()

  const { openDialog } = useDialog()

  const personReferenceId = usePersonId()

  const onLogin = (token: string) => {
    if (!decodeToken(token)['partial']) {
      unauthorizedDispatch(reset())
    }

    document.body.classList.add('loading')
  }

  useBiometricalLogin({
    onSuccess: onLogin,
    trustedDeviceReferenceId,
    enabled: !!biometricLoginActive && !biometricLoginDisabled && !personReferenceId,
  })

  const {
    handleLoginWithEmailAndPassword,
    logingInWithEmailAndPassword: isLoading,
    error,
  } = useLogin({
    onSuccess: async (res) => {
      onLogin(res.token)

      if (!decodeToken(res.token).partial && (await shouldOfferBiometricLogin())) {
        openDialog(DialogNames.BiometricLogin, { openSnackbar })
      }
    },
  })

  useRedirectUrlHandling({ shouldRead: true })

  const dispatch = useUnauthorizedDispatch()
  const { state, setState } = useLogoutState()

  const handleSubmit = ({ email, password }: LoginFormFields) => {
    state !== null && setState(null)
    dispatch(setEmail(email))
    handleLoginWithEmailAndPassword({ email, password })
  }

  return <LoginScreen onSubmit={handleSubmit} processing={isLoading} error={error} />
}

export const getStaticProps = withTranslation

export default LoginPage
